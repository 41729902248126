import { importAll } from 'js/helpers';


import 'normalize.css';
import '@fancyapps/fancybox/dist/jquery.fancybox.css';
import 'slick-carousel/slick/slick.css';
import 'css/init.sass';


importAll(require.context('css/components/', true));
// importAll(require.context('../fonts/', true));